import axios from "@/config/http";
// import qs from 'qs'
export const businessDeveloperApi = {
  // 获取table数据
  getTableData(params) {
    return axios.get("/cloud-platform-backend-facade/front-api/v1/user/inspect", {
      params,
    });
  },
  // 获取审核详细信息
  getReviewData(params) {
    return axios.get(`/cloud-platform-backend-facade/front-api/v1/user/inspect/${params}`);
  },
  //审核通过
  PassReview(params) {
    return axios.put(
      "/cloud-platform-backend-facade/front-api/v1/user/inspect/business-developer",
      params
    );
  },

};
