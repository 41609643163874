<template>
  <div>
    <a-modal
      @ok="handleOk"
      v-model="config.show"
      :width="config.width"
      style="padding: 0px"
      @cancel="handleCancel"
      :confirm-loading="confirmLoading"
    >
      <template slot="title"> 审核不通过原因 </template>
      <div class="modal_right add_positon">
        <a-textarea
          :maxLength="100"
          class="formInputWidth"
          v-model="reason"
          placeholder="请输入审核不通过原因"
          @change="remarksOnchange"
          :auto-size="{ minRows: 8, maxRows: 8 }"
        />
        <span style="position: absolute; right: 30px; bottom: 80px"
          >已输入{{ detLength }}/100</span
        >
      </div>
    </a-modal>
  </div>
</template>
<script>
import { businessDeveloperApi } from "@/api/businessDeveloper.js";
export default {
  props: {
    config: {
      type: Object,
      default: () => {
        return {
          type: "",
          id: 0,
          show: false,
          record: null,
          title: "人员班组",
          width: "60%",
        };
      },
    },
  },
  data() {
    return {
      reason: "",
      detLength: 0,
    };
  },
  mounted() {},
  methods: {
    handleCancel() {
      this.$emit("handleCancel");
    },
    handleOk() {
      if (this.reason === "") {
        this.$message.warn("处理结果不能为空");
      } else {
        this.NoPassReview();
      }
    },
    //审核不通过
    NoPassReview() {
      let params = {
        ...this.config.record,
        reason: this.reason,
      };
      businessDeveloperApi.PassReview(params).then((res) => {
        if (res.success) {
          this.$emit("handleOk");
        } else {
          this.$message.error(res.errorMsg);
        }
      });
    },
    remarksOnchange() {
      this.$nextTick(() => {
        this.detLength = this.reason.length;
      });
    },
  },
};
</script>
<style scoped>
.header_contain {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}
.span_color {
  color: red;
}

.formStyle {
  height: 70px;
}

.line {
  border: 2px solid #f0f2f5;
  margin-bottom: 20px;
}

.modal_title {
  font-size: 12px;
}
.modal_text {
  color: rgb(187, 184, 184);
  /* font-size: 12px; */
}
.notice_text {
  color: #33333357;
  font-size: 12px;
  margin-bottom: 10px;
  font-weight: 900;
}
.modal_text {
  text-align: left;
  font-size: 20px;
  font-weight: 600;
  padding-top: 4px;
}
.modal_logo {
  margin-left: 10px;
  margin-top: -6px;
  color: rgba(29, 29, 29, 0.753);
}
.modal_stext {
  color: rgb(136, 131, 131);
  font-size: 12px;
  padding-left: 30px;
  font-weight: 200;
  height: 60px;
}
</style>